import { HYDRATE } from 'next-redux-wrapper'

import { TagTypes } from '@redux/api/tagTypes'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { ApiResponseErrorType } from '@Types/api/responseError'
import { aiSearchWidgetUtils } from '@aiSearchWidget/utils/common'
import { baseUrl } from '@constants/api/api'
import { XAPIUserKey } from '@constants/api/tokens'
import { AuthTokenExceptionApiName, authTokenExceptionApi } from '@constants/authTokenException'
import userSettingsStorage from '@services/browserStorages/storageEntities/userSettings'
import authStorage from '@storageServices/storageEntities/auth'

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    headers: {
      'X-API-User-Key': XAPIUserKey,
    },
    mode: 'cors',
    prepareHeaders: (headers, { endpoint }) => {
      const token = authStorage.getValueByKey('X-Auth-Token')

      token && !authTokenExceptionApi.has(endpoint) && headers.set('X-Auth-Token', token)
      headers.set('X-currency', userSettingsStorage.getValue()?.currency || '')

      if (aiSearchWidgetUtils.getXKey()) {
        headers.set('X-API-User-Key', aiSearchWidgetUtils.getXKey() as string)
      }

      return headers
    },
  }) as BaseQueryFn<FetchArgs | string, unknown, ApiResponseErrorType, object, FetchBaseQueryMeta>,
  endpoints: build => ({
    [AuthTokenExceptionApiName.DOWNLOAD_FILE]: build.query<void, string>({
      query: downloadUrl => ({
        responseHandler: async data => {
          const blob = await data.blob()

          const href = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = href
          link.style.position = 'absolute'
          link.setAttribute(
            'download',
            data.headers.get('content-disposition')?.split('filename=')[1] || data.url.split('/').pop() || 'ticket.pdf'
          )
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        },
        url: downloadUrl,
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      // @ts-ignore
      return action.payload[reducerPath]
    }
  },
  tagTypes: Object.values(TagTypes),
})

export const {
  middleware: apiMiddleware,
  reducer: apiReducer,
  reducerPath: apiReducerPath,
  useLazyDownloadFileQuery,
} = api
