import { userEndpoints } from '@redux/features/user/api'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { DeviceType } from '@Types/common/device'
import isClient from '@components/common/isClient'
import { ApplicationNames } from '@constants/applications/applicationNames'
import defaultParameters from '@constants/defaultParameters/defaultParameters'
import getAppConfig from '@services/config/config'
import userSettingsStorage from '@storageServices/storageEntities/userSettings'
import { ThemeNames } from '@themes/themes'

import type { ICommonAppState } from './types/commonAppState'

const userSettingsStorageData = userSettingsStorage.getValue()
const { publicRuntimeConfig } = getAppConfig()
const getInitTheme = () => {
  const themeValues = Object.values(ThemeNames)
  const localStorageTheme =
    userSettingsStorageData?.theme &&
    themeValues.includes(userSettingsStorageData.theme) &&
    userSettingsStorageData.theme
  const initEnvTheme =
    !localStorageTheme &&
    themeValues.findIndex(theme => theme === (publicRuntimeConfig.theme as ThemeNames)) > -1 &&
    publicRuntimeConfig.theme
  return (localStorageTheme ? localStorageTheme : initEnvTheme ? initEnvTheme : defaultParameters.theme) as ThemeNames
}

const initialState: ICommonAppState = {
  device: { type: DeviceType.DESKTOP },
  isPageInteraction: publicRuntimeConfig.site === ApplicationNames.SEARCH_WIDGET,
  isRtl: isClient ? document.dir === 'rtl' : false,
  theme: getInitTheme(),
}

const commonAppSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(userEndpoints.getCurrentUserInfo.matchFulfilled, (state, { payload }) => {
      if (payload?.uid) state.isPageInteraction = true
    })
  },
  initialState,
  name: 'commonApp',

  reducers: {
    setDeviceType: (state, { payload }: PayloadAction<DeviceType>) => {
      state.device.type = payload
    },
    setPageInteraction: (state, action: PayloadAction<boolean>) => {
      state.isPageInteraction = action.payload
    },
    setTheme: (state, action: PayloadAction<ThemeNames>) => {
      userSettingsStorage.addValues({ theme: action.payload })
      state.theme = action.payload
    },
  },
})

export const { setDeviceType, setPageInteraction, setTheme } = commonAppSlice.actions

export default commonAppSlice.reducer
